import city from './city.json'
const obj={
  value:'',
  label:'全部'
}

export const cityList=()=>{//获取省市区数据，加了全部的
    let codes=city.provinces.options
    codes.forEach(item=>{
      if(item.children[0].value!=''){
        item.children=[obj,...item.children]
      }
      item.children.forEach(item2=>{
        if(item2.areas){
          item2.children=[obj,...item2.areas.children]
        }else{
          item2.areas=''
        }
        
        // item2.areas=''
      })
    })
    return codes
}

export const provinceList=()=>{//获取省
  let codes=city.provinces.options
  // codes.forEach(item=>{
  //   if(item.children[0].value!=''){
  //     item.children=[obj,...item.children]
  //   }
  //   item.children.forEach(item2=>{
  //     if(item2.areas){
  //       item2.children=[obj,...item2.areas.children]
  //     }else{
  //       item2.areas=''
  //     }
      
  //   })
  // })
 let items=codes.map(item=>{
    return {
      label:item.label,
      value:item.value
    }
  })
  return items
}


// export const cityList=()=>{//获取省市区数据，未加全部
//   let codes=city.provinces.options

//   codes.forEach(item=>{
//     item.children.forEach(item2=>{
//       item2.children=item2.areas.children
      
//       // item2.areas=''
//     })
//   })
//   return codes
// }